<template>
    <div>
        <div class="card card-custom gutter-b p-5 w-100">
            <div class="w-100 d-flex flex-row flex-wrap justify-content-around align-items-center mb-5">
                <div class="w-25">
                    <span class="btn btn-outline-danger btn-md" @click="showResult()" id="closeContainer">
                        <span class="svg-icon svg-icon-md svg-icon-light-danger m-0">
                            <svg height="1em" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg" id="close">
                                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </span>
                    </span>
                </div>
                <div class="w-50 d-flex justify-content-center align-items-center">
                    <h3>{{ lessonTitle }}</h3>
                </div>
                <div class="w-25"></div>
            </div>
            <div v-if="!loadingResults" class="d-flex flex-column align-items-center justify-content-center">
                <div
                    v-for="(result, index) in results"
                    :key="index"
                    :class="result.solution != result.answer ? 'wrong-answer-border' : ''"
                    class="my-5 pb-5 border-bottom border-secondary w-50">
                    <div class="mb-10">
                        <h2>
                            <i class="text-muted mr-5">{{ index + 1 + '/' + results.length }}</i>
                            {{ result.question }}
                        </h2>
                        <div class="d-flex flex-column justify-content-around" style="height: 40%; width: 100%">
                            <div class="d-flex flex-row justify-content-around align-items-center my-2">
                                <b-form-checkbox
                                    :id="result.id + 'a'"
                                    disabled
                                    :checked="result.answer.includes('A')"
                                    size="md"
                                />
                                <label
                                    :class="getClass(result, 'A')"
                                    :for="result.id + 'a'"
                                    class="h3 m-0"
                                    style="width: 80%"
                                >
                                    {{ result.answer_a }}
                                </label>
                            </div>
                            <div class="d-flex flex-row justify-content-around align-items-center my-2">
                                <b-form-checkbox
                                    :id="result.id + 'b'"
                                    disabled
                                    :checked="result.answer.includes('B')"
                                    size="md"
                                />
                                <label
                                    :class="getClass(result, 'B')"
                                    :for="result.id + 'b'"
                                    class="h3 m-0"
                                    style="width: 80%"
                                >
                                    {{ result.answer_b }}
                                </label>
                            </div>
                            <div
                                v-if="result.answer_c != ' ' && result.answer_c != '' && result.answer_c"
                                class="d-flex flex-row justify-content-around align-items-center my-2"
                            >
                                <b-form-checkbox
                                    :id="result.id + 'c'"
                                    disabled
                                    :checked="result.answer.includes('C')"
                                    size="md"
                                />
                                <label
                                    :class="getClass(result, 'C')"
                                    :for="result.id + 'c'"
                                    class="h3 m-0"
                                    style="width: 80%"
                                >
                                    {{ result.answer_c }}
                                </label>
                            </div>
                            <div
                                v-if="result.answer_d != ' ' && result.answer_d != '' && result.answer_d"
                                class="d-flex flex-row justify-content-around align-items-center my-2"
                            >
                                <b-form-checkbox
                                    :id="result.id + 'd'"
                                    disabled
                                    :checked="result.answer.includes('D')"
                                    size="md"
                                />
                                <label
                                    :class="getClass(result, 'D')"
                                    :for="result.id + 'd'"
                                    class="h3 m-0"
                                    style="width: 80%"
                                >
                                    {{ result.answer_d }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="d-flex flex-column align-items-center justify-content-center">
                <b-spinner></b-spinner>
                <p class="mt-3">Veuiilez patienter...</p>
            </div>
        </div>
    </div>
</template>

<script>
import { GET_SURVEY_ITEMS_USERS } from '@/core/services/store/api/survey-items-users.service';
export default {
    name: 'AnswerUser',
    props: {
        classeUserId: {
            type: Number,
            default: null
        },
        lessonId: {
            type: Number,
            default: null
        },
        lessonTitle: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            results: [],
            loadingResults: true
        };
    },
    methods: {
        showResult() {
            this.$emit('showResult');
        },
        getClass(result, answer) {
            if (result.solution.includes(answer)) {
                return 'good-answer';
            } else {
                if (result.answer.includes(answer)) {
                    return 'wrong-answer';
                } else {
                    return 'neutral-answer';
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch(GET_SURVEY_ITEMS_USERS, {classUserId: this.classeUserId, lessonId: this.lessonId}).then((result) => {
            this.results = result.data;
            this.loadingResults = false;
        });
    }
};
</script>

<style>
    #close {
        fill: #f64e60;
    }
    #closeContainer:hover #close {
        fill: white;
    }

    .wrong-answer-border {
        border: 2px solid red !important;
        border-radius: 3px;
        padding: 10px;
    }

    .wrong-answer {
        text-decoration: line-through;
        color: red;
    }

    .good-answer {
        color: green;
    }

    .neutral-answer {
        color: #3f4254;
    }
</style>