<template>
    <div class="card card-custom gutter-b flex-row align-items-center justify-content-between p-5">
        <div class="w-25">
            <router-link :to="routerLink">
                <span class="btn btn-outline-danger btn-md">
                    <span class="svg-icon svg-icon-md svg-icon-light-danger">
                        <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                    </span>
                    {{ !mobile() ? $t('CLASS.BACK') : '' }}
                </span>
            </router-link>
        </div>
        <h1 class="ml-5 mb-0 align-self-center">{{ title }}</h1>
        <div class="w-25" />
    </div>
</template>

<script>
export default {
    name: 'Bandeau',
    // Propriétés du composant
    props: {
        // Propriétés ici
        title: {
            type: String,
            default: ''
        },
        redirectLink: {
            type: String,
            default: 'dashboard'
        },
        params: {
            type: Object,
            default: null
        }
    },
    // Données du composant
    data() {
        return {
            routerLink: {}
            // Données ici
        };
    },
    // Méthodes du composant
    methods: {
        mobile() {
            return window.screen.availWidth <= 992;
        }
        // Méthodes ici
    },
    // Computed properties du composant
    computed: {
        // Computed properties ici
    },
    // Hooks du cycle de vie du composant
    created() {
        // Code exécuté lors de la création du composant
    },
    mounted() {
        if (!this.params) {
            this.routerLink = { name: this.redirectLink };
        } else {
            this.routerLink = { name: this.redirectLink, params: this.params };
        }

        // Code exécuté lorsque le composant est monté dans le DOM
    }
    // Autres hooks du cycle de vie du composant
    // ...
};
</script>

<style scoped>
/* Styles spécifiques au composant ici */
</style>
