<template>
    <!-- Contenu du template -->
    <div>
        <Bandeau
            :title="classeUser.user.lastname + ' ' + classeUser.user.firstname"
            :redirectLink="'classroom'"
            :params="{ id: classeUser.sessionInfo.id }"
        />
        <LessonsList :classeUser="classeUser" :fieldsLessons="fieldsLessons()" :fieldsLessonsMobile="fieldsLessonsMobile" />
    </div>
</template>

<script>
import Bandeau from './Components/Bandeau.vue';
import LessonsList from './Components/LessonsList.vue';

export default {
    name: 'ClassroomTuteur',
    props: {
        // Propriétés du composant
        classeUser: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            // Données du composant
            fieldsLessonsMobile: [
                {
                    key: 'index',
                    label: '',
                    tdClass: 'align-middle'
                },
                {
                    key: 'title',
                    label: 'Titre',
                    tdClass: 'align-middle'
                },
                {
                    key: 'avancement',
                    label: 'Progression',
                    tdClass: 'align-middle'
                },
                {
                    key: 'action',
                    label: 'Action',
                    tdClass: 'align-middle'
                }
            ]
        };
    },
    methods: {
        // Méthodes du composant
        fieldsLessons() {
            let baseFields = [
                {
                    key: 'index',
                    label: '',
                    tdClass: 'align-middle'
                },
                {
                    key: 'title',
                    label: 'Titre',
                    tdClass: 'align-middle'
                },
                {
                    key: 'description',
                    label: 'Description',
                    tdClass: 'align-middle',
                },
                {
                    key: 'start',
                    label: 'Commencé le',
                    tdClass: 'align-middle'
                },
                {
                    key: 'avancement',
                    label: 'Progression',
                    tdClass: 'align-middle'
                },
                {
                    key: 'attempt',
                    label: 'Nombre de tentative',
                    tdClass: 'align-middle'
                }
            ];

            // Check if there are any completed lessons
            if (
                this.classeUser.lessons.some((lesson) => lesson.userResult && lesson.userResult.attempts > 0)
            ) {
                baseFields.push({
                    key: 'score',
                    label: 'Dernier score',
                    tdClass: 'align-middle'
                });
               
                baseFields.push({
                    key: 'lastEval',
                    label: '',
                    tdClass: 'align-middle'
                });
            }

            return baseFields;
        }
    },
    computed: {
        // Propriétés calculées du composant
    },
    mounted() {
        // Code exécuté lorsque le composant est monté
    },
    components: {
        Bandeau,
        LessonsList
    }
};
</script>

<style scoped>
/* Styles spécifiques au composant */
</style>
