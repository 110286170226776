<template>
    <div>
        <div class="card card-custom gutter-b pt-5" v-if="!showModalUserAnswers">
            <h3 class="mb-5 mx-5 mt-1">
                Cours disponibles
                <span class="text-muted font-weight-bold">( {{ classeUser.totalLessons }} )</span>
            </h3>
            <BAlert
                v-if="classeUser.classeInfo.classroom === 1"
                :show="
                    classeUser.classeInfo.classroom === 1 &&
                    (new Date(classeUser.sessionInfo.start_date) > new Date() || classeUser.sessionInfo.started === 0)
                "
            >
                <h4 class="alert-heading">
                    <b-icon icon="info-circle-fill"></b-icon>
                    Information
                </h4>
                <p v-if="new Date(classeUser.sessionInfo.start_date) > new Date()" class="m-0">
                    La session n'as pas encore commencée. Merci de vous reconnecter à partir du
                    {{ formatDateAndTime(classeUser.sessionInfo.start_date) }}.
                </p>
                <p v-else class="m-0">
                    Vous pourrez commencer votre parcours de formation une fois la vidéoconférence d'introduction terminée ou dès que votre
                    tuteur aura activé votre session.
                </p>
            </BAlert>
            <b-table :fields="!mobile() ? fieldsLessons : fieldsLessonsMobile" :items="classeUser.lessons" hover responsive>
                <template #cell(index)="row">
                    <div class="d-flex">
                        <p class="mb-0 ml-5">#{{ row.index + 1 }}</p>
                    </div>
                </template>
                <template #cell(title)="row">
                    <p class="mb-0">{{ row.item.title }}</p>
                </template>
                <template #cell(description)="row">
                    <nl2br v-if="row.item.description" :text="row.item.description" class-name="customScroll my-2 nl2brLesson" tag="p" />
                </template>
                <template #cell(start)="row">
                    <p v-if="row.item.userResult" class="mb-0">
                        {{ formatDate(row.item.userResult.created_at) }}
                    </p>
                    <p v-else class="mb-0"></p>
                </template>
                <template #cell(finish)="row">
                    <p v-if="row.item.userResult && row.item.userResult.score !== null" class="mb-0">
                        {{ formatDate(row.item.userResult.updated_at) }}
                    </p>
                    <p v-else class="mb-0"></p>
                </template>
                <template #cell(duration)="row">
                    <!--                        row.item.duration is a value in seconds, we need to convert it to hours, minutes and seconds-->
                    <p class="mb-0">
                        {{
                            Math.floor(row.item.duration / 3600) +
                            'h ' +
                            Math.floor((row.item.duration % 3600) / 60) +
                            'm ' +
                            Math.floor((row.item.duration % 3600) % 60) +
                            's'
                        }}
                    </p>
                </template>
                <template #cell(avancement)="row">
                    <div class="d-flex align-items-center">
                        <div style="width: 100%">
                            <b-progress :max="row.item.totalItems" height="2vh" width="100%">
                                <b-progress-bar v-if="row.item.userResult !== null && row.item.userResult.score !== null" :value="100">
                                    <span
                                        v-if="
                                            (row.item.totalItemsEnd / row.item.totalItems) * 100 === 100 ||
                                            (row.item.userResult && row.item.userResult.state === 3)
                                        "
                                    >
                                        Terminé
                                    </span>
                                    <span v-else-if="(row.item.totalItemsEnd / row.item.totalItems) * 100 > 10">
                                        {{ parseInt((row.item.totalItemsEnd / row.item.totalItems) * 100) }}%
                                    </span>
                                </b-progress-bar>
                                <b-progress-bar v-else-if="row.item.userResult !== null" :value="row.item.totalItemsEnd">
                                    <span
                                        v-if="
                                            (row.item.totalItemsEnd / row.item.totalItems) * 100 === 100 ||
                                            (row.item.userResult && row.item.userResult.state === 3)
                                        "
                                    >
                                        Terminé
                                    </span>
                                    <span v-else-if="(row.item.totalItemsEnd / row.item.totalItems) * 100 > 10">
                                        {{ parseInt((row.item.totalItemsEnd / row.item.totalItems) * 100) }}%
                                    </span>
                                </b-progress-bar>
                                <b-progress-bar v-else :value="row.item.totalItemsEnd">
                                    <span
                                        v-if="
                                            (row.item.totalItemsEnd / row.item.totalItems) * 100 === 100 ||
                                            (row.item.userResult && row.item.userResult.state === 3)
                                        "
                                    >
                                        Terminé
                                    </span>
                                    <span v-else-if="(row.item.totalItemsEnd / row.item.totalItems) * 100 > 10">
                                        {{ parseInt((row.item.totalItemsEnd / row.item.totalItems) * 100) }}%
                                    </span>
                                </b-progress-bar>
                            </b-progress>
                        </div>
                    </div>
                </template>
                <template #cell(action)="row">
                    <div
                        v-if="
                            classeUser.sessionInfo &&
                            ((classeUser.sessionInfo.end_date && passedDate(classeUser.sessionInfo.end_date)) ||
                                classeUser.sessionInfo.started === 0 ||
                                new Date(classeUser.sessionInfo.start_date) > new Date())
                        "
                        v-b-tooltip.hover.top="
                            classeUser.sessionInfo.started === 0 || new Date(classeUser.sessionInfo.start_date) > new Date()
                                ? 'La session n\'a pas encore démarrée'
                                : 'Session terminée'
                        "
                        class="w-100 d-flex justify-content-center py-3"
                    >
                        <span class="disabled svg-icon svg-icon-danger svg-icon-md">
                            <inline-svg src="/media/svg/icons/General/Lock.svg" />
                        </span>
                    </div>
                    <div
                        v-else-if="
                            (row.index === 0 && classeUser.classeInfo.on) ||
                            (classeUser.classeInfo.linear &&
                                row.index > 0 &&
                                classeUser.lessons[row.index - 1].userResult &&
                                classeUser.lessons[row.index - 1].userResult.state === 3 &&
                                classeUser.classeInfo.on) ||
                            (!classeUser.classeInfo.linear && classeUser.classeInfo.on)
                        "
                        class="w-100 d-flex justify-content-center py-3"
                    >
                        <span v-if="row.item.userResult">
                            <div
                                v-if="row.item.userResult && row.item.userResult.state === 3"
                                class="d-flex justify-content-center align-items-center"
                            >
                                <span class="svg-icon svg-icon-md svg-icon-warning">
                                    <inline-svg src="/media/svg/icons/Clothes/Crown.svg" />
                                </span>
                                <p class="font-weight-bold text-warning ml-5 mb-0 pt-1">
                                    Validé
                                    <span v-if="row.item.userResult && row.item.userResult.score"
                                        >(Score : {{ row.item.userResult.score }}%)</span
                                    >
                                </p>
                            </div>
                            <router-link
                                v-else-if="row.item.userResult && row.item.userResult.state === 2"
                                :to="{
                                    name: 'lesson',
                                    params: {
                                        classeUserId: $route.params.classeUserId,
                                        id: row.item.id
                                    }
                                }"
                                replace
                            >
                                <b-button class="btn-outline-primary d-flex justify-content-center align-items-center" variant="primary">
                                    <p class="mb-0 ml-3 mr-1">Reprendre</p>
                                    <span class="svg-icon svg-icon-md svg-icon-light-success ml-0">
                                        <inline-svg src="/media/svg/icons/Media/Play.svg" />
                                    </span>
                                </b-button>
                            </router-link>
                            <router-link
                                v-else
                                :to="{
                                    name: 'lesson',
                                    params: {
                                        classeUserId: $route.params.classeUserId,
                                        id: row.item.id
                                    }
                                }"
                                replace
                            >
                                <b-button class="btn-outline-success d-flex" variant="success">
                                    <p class="mb-0 ml-3 mr-1">Commencer</p>
                                    <span class="svg-icon svg-icon-md svg-icon-light-success ml-0">
                                        <inline-svg src="/media/svg/icons/Media/Play.svg" />
                                    </span>
                                </b-button>
                            </router-link>
                        </span>
                        <router-link
                            v-else
                            :to="{
                                name: 'lesson',
                                params: {
                                    classeUserId: $route.params.classeUserId,
                                    id: row.item.id
                                }
                            }"
                            replace
                        >
                            <b-button class="btn-outline-success d-flex justify-content-center align-items-center px-2" variant="success">
                                <p class="mb-0 ml-3 mr-1">Commencer</p>
                                <span class="svg-icon svg-icon-md svg-icon-light-success">
                                    <inline-svg src="/media/svg/icons/Media/Play.svg" />
                                </span>
                            </b-button>
                        </router-link>
                    </div>
                    <div
                        v-else
                        v-b-tooltip.hover.top="
                            classeUser.classeInfo.on
                                ? 'Vous devez valider le cours précédent pour pouvoir continuer'
                                : 'Classe indisponible'
                        "
                        class="w-100 d-flex justify-content-center py-3"
                    >
                        <span class="disabled svg-icon svg-icon-danger svg-icon-md">
                            <inline-svg src="/media/svg/icons/General/Lock.svg" />
                        </span>
                    </div>
                </template>
                <template #cell(attempt)="row">
                    <p v-if="row.item.userResult" class="mb-0">
                        {{ row.item.userResult.attempts }}
                    </p>
                    <p v-else class="mb-0"></p>
                </template>
                <template #cell(summary)="row">
                    <a
                        v-if="row.item.summary && row.item.userResult && row.item.userResult.state === 3"
                        :href="row.item.summary"
                        target="_blank"
                        v-b-tooltip:top.hover="'Télécharger la fiche synthèse'"
                    >
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                            <img src="/media/png/icons/export/PDF_icon.png" height="20px" alt="Icon PDF" />
                        </span>
                    </a>
                    <img
                        v-else-if="row.item.summary"
                        src="/media/png/icons/Course/pdf_gris.png"
                        height="20px"
                        alt="Icon PDF désactivé"
                        v-b-tooltip:top.hover="'Terminez la leçon pour télécharger la fiche synthèse'"
                    />
                    <p v-else class="m-0">Aucune</p>
                </template>
                <template #cell(score)="row">
                    <p v-if="row.item.userResult && row.item.userResult.score" class="mb-0">{{ row.item.userResult.score }}%</p>
                </template>
                <template #cell(lastEval)="row">
                    <b-button
                        v-if="row.item.userResult && row.item.userResult.attempts > 0"
                        class="btn-outline-success d-flex justify-content-center align-items-center px-2"
                        variant="success"
                        @click="modalUserAnswers(row.item.id, row.item.title)"
                    >
                        <p class="mb-0 ml-3 mr-1">Voir la dernière évaluation</p>
                        <span class="svg-icon svg-icon-md svg-icon-light-success">
                            <inline-svg src="/media/svg/icons/Media/Play.svg" />
                        </span>
                    </b-button>
                    <p v-else class="m-0">Aucune</p>
                </template>
                <template #empty>
                    <p class="bg-secondary rounded py-5" disabled>
                        <i class="text-dark">{{ $t('LESSON.EMPTY') }}</i>
                    </p>
                </template>
            </b-table>
        </div>
        <AnswerUser
            v-else-if="showModalUserAnswers"
            :classeUserId="classeUser.id"
            :lessonId="selectedLesson.id"
            :lessonTitle="selectedLesson.title"
            @showResult="modalUserAnswers"
        />
    </div>
</template>

<script>
import moment from 'moment';
import Nl2br from 'vue-nl2br';
import AnswerUser from './AnswerUser.vue';

export default {
    name: 'NomDuComposant',
    data() {
        return {
            selectedLesson: {
                id: null,
                title: null
            },
            showModalUserAnswers: false
        };
    },
    props: {
        classeUser: {
            type: Object,
            default: null
        },
        fieldsLessons: {
            type: Array,
            default: () => {
                return [
                    {
                        key: 'index',
                        label: '',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'title',
                        label: 'Titre',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'description',
                        label: 'Description',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'start',
                        label: 'Commencé le',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'avancement',
                        label: 'Progression',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'action',
                        label: '',
                        tdClass: 'align-middle'
                    }
                ];
            }
        },
        fieldsLessonsMobile: {
            type: Array,
            default: () => {
                return [
                    {
                        key: 'index',
                        label: '',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'title',
                        label: 'Titre',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'avancement',
                        label: 'Progression',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'action',
                        label: 'Action',
                        tdClass: 'align-middle'
                    }
                ];
            }
        }
    },
    components: {
        // Composants importés
        Nl2br,
        AnswerUser
    },
    methods: {
        // Méthodes du composant
        mobile() {
            return window.screen.availWidth <= 992;
        },
        passedDate(date) {
            return moment(date).isBefore(moment());
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        formatDateAndTime(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY à HH:mm');
            }
        },
        modalUserAnswers(lessonid = null, lessonTitle = null) {
            this.selectedLesson.id = lessonid;
            this.selectedLesson.title = lessonTitle;
            this.showModalUserAnswers = !this.showModalUserAnswers;
        }
    },
    mounted() {
        if (this.classeUser.classeInfo && this.classeUser.classeInfo.classroom === 1) {
            this.fieldsLessons.splice(5, 0, {
                key: 'duration',
                label: 'Temps passé',
                tdClass: 'align-middle'
            });
        }
    }
};
</script>

<style scoped>
/* Styles spécifiques au composant */
</style>
