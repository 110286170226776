<template>
    <div>
        <Bandeau :title="classeUser.classeInfo.title" />
        <InfoClasse :classeUser="classeUser" />
        <div v-if="classeUser.classeInfo.classroom === 1" class="card card-custom gutter-b p-5 align-items-center w-100">
            <div class="w-100 h-100 mx-auto d-flex flex-column align-items-center">
                <Visios :videoconferences="classeUser.videoconferences" />
            </div>
        </div>
        <div
            v-if="!classeUser.classeInfo.on && classeUser.state !== 3"
            class="card card-custom py-5 px-10 w-75 mx-auto gutter-b border border-danger font-weight-bold text-danger text-center"
        >
            <h4 class="m-0">Classe temporairement indisponible. Son contenu est en cours de révision</h4>
        </div>
        <LessonsList :classeUser="classeUser" :fieldsLessons="fieldsLessons()" :fieldsLessonsMobile="fieldsLessonsMobile" />
    </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import getDocument from '@/core/services/certificate.service.js';
import Bandeau from './Components/Bandeau.vue';
import InfoClasse from './Components/InfoClasse.vue';
import Visios from '../Visios.vue';
import LessonsList from './Components/LessonsList.vue';

export default {
    name: 'ClassroomApprenant',
    props: {
        isPresent: {
            type: Boolean,
            default: true
        },
        classeUser: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loadingReleve: false,
            fieldsLessonsMobile: [
                {
                    key: 'index',
                    label: '',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'title',
                    label: 'Titre',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'avancement',
                    label: 'Progression',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'action',
                    label: 'Action',
                    tdClass: 'align-middle'
                }
            ]
        };
    },
    components: {
        Visios,
        Bandeau,
        InfoClasse,
        LessonsList
    },
    methods: {
        getDocument() {
            getDocument([this.classeUser]).then((data) => {
                data.open();
            });
        },

        mobile() {
            return window.screen.availWidth <= 992;
        },
        hasSummary() {
            for (let i = 0; i < this.classeUser.lessons.length; i++) {
                if (this.classeUser.lessons[i].summary !== null) {
                    return true;
                }
            }
            return false;
        },
        fieldsLessons() {
            let baseFields = [
                {
                    key: 'index',
                    label: '',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'title',
                    label: 'Titre',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'description',
                    label: 'Description',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'start',
                    label: 'Commencé le',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'avancement',
                    label: 'Progression',
                    tdClass: 'align-middle text-center'
                },
                {
                    key: 'action',
                    label: '',
                    tdClass: 'align-middle'
                }
            ];

            // Check if there are any completed lessons or available summaries
            if (
                this.classeUser.lessons.some((lesson) => lesson.userResult && lesson.userResult.state === 3) &&
                this.classeUser.lessons.some((lesson) => lesson.summary)
            ) {
                baseFields.push({
                    key: 'summary',
                    label: 'Fiche synthèse',
                    tdClass: 'align-middle text-center'
                });
            }

            return baseFields;
        }
    },
    computed: {
        checkUser() {
            if (this.classeUser.user_id === this.$store.getters.currentUser.id) {
                return true;
            } else {
                return false;
            }
        }
    },
    async mounted() {
        if (!this.isPresent) {
            //display persistant toast
            this.$bvToast.toast("En raison d'une inactivité prolongée, vous avez été déconnecté de la classe.", {
                title: 'Vous avez été déconnecté de la classe',
                variant: 'danger',
                solid: true,
                noAutoHide: true,
                appendToast: true
            });
        }
        await this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Lesson' }]);
    }
};
</script>

<style scoped>
.table {
    text-align: center;
}

.table td {
    vertical-align: middle !important;
}

.font-weight-bold {
    font-weight: 600 !important;
}

.nl2br {
    overflow-y: auto;
    text-align: left;
    max-height: 10vh;
    padding-right: 2px;
}

.nl2brLesson {
    max-height: 7em;
    overflow-y: auto;
    text-align: left;
    padding-right: 2px;
}

.customScroll::-webkit-scrollbar-track {
    background-color: #f1f4f7;
}

.customScroll::-webkit-scrollbar {
    width: 0.25em;
    background-color: #f1f4f7;
}

.customScroll::-webkit-scrollbar-thumb {
    background-color: #085a82;
}
</style>
