<template>
    <div :class="mobile() ? 'flex-column' : ''" class="d-flex justify-content-between align-items-center">
        <div
            :style="mobile() ? '' : 'width: 65%; height: 20rem;'"
            class="card card-custom gutter-b d-flex flex-row justify-content-around align-items-center py-2"
        >
            <img
                ref="EditMiniature"
                :class="mobile() ? 'mb-10' : ''"
                :src="classeUser.classeInfo.miniature"
                alt="text"
                class="m-0 rounded"
                style="max-width: 60%; max-height: 100%; vertical-align: middle"
            />
            <section v-if="mobile() && (classeUser.state === 1 || (classeUser.state === 2 && !classeUser.score))">
                <p class="mb-0">
                    <span class="font-weight-bold">Score minimum à atteindre pour valider la classe</span>
                </p>
                <h3 class="p-5 text-center my-2">
                    {{ classeUser.classeInfo.goal + '%' }}
                </h3>
            </section>
            <div
                :style="mobile() ? 'max-width:100%;' : 'max-width:55%;'"
                class="d-flex flex-column justify-content-center px-5"
                style="min-width: 40%; max-height: 95%"
            >
                <span class="font-weight-bold pb-2">{{ $t('CLASS.DESCRIPTION.LABEL') }}</span>
                <nl2br
                    v-if="classeUser.classeInfo.description"
                    :text="classeUser.classeInfo.description"
                    class-name="customScroll mh-100 my-2 ml-5 pl-5 border-secondary border-left nl2br"
                    tag="p"
                />
            </div>
        </div>
        <div
            v-if="!mobile()"
            :class="
                classeUser.state === 1 ||
                (classeUser.state === 2 && !classeUser.score) ||
                (classeUser.classeInfo && classeUser.classeInfo.classroom)
                    ? 'justify-content-center'
                    : 'justify-content-between'
            "
            :style="mobile() ? '' : 'width: 32%;'"
            class="card card-custom gutter-b p-10 align-items-center"
            style="height: 20rem"
        >
            <section
                v-if="
                    classeUser.classeInfo.classroom &&
                    (classeUser.state === 3 || (classeUser.sessionInfo && new Date(classeUser.sessionInfo.end_date) < new Date()))
                "
                class="text-center"
            >
                <div v-if="classeUser.state === 3">
                    <p class="mb-0 font-weight-bold">Félicitations,</p>
                    <span class="text-center">
                        Vous avez terminé cette classe avec un score de {{ Number(classeUser.score).toFixed(0) + '%' }}.
                    </span>
                </div>
                <div v-else-if="classeUser.sessionInfo && new Date(classeUser.sessionInfo.end_date) < new Date()">
                    <p class="mb-0 font-weight-bold">Cette session est maintenant terminée.</p>
                    <span class="text-center"> Votre score final est de {{ Number(classeUser.score).toFixed(0) + '%' }}. </span>
                </div>
                <div class="w-100 mx-auto d-flex flex-column align-items-center justify-content-center">
                    <b-button class="mt-2" pill size="sm" variant="outline-primary" @click="downloadDocument()">
                        <b-icon v-if="!loadingReleve" icon="journal-bookmark-fill"></b-icon>
                        <b-spinner v-else small></b-spinner>
                        Télécharger mon relevé de parcours individuel
                    </b-button>
                    <b-button
                        v-if="classeUser.state === 3 && classeUser.classeInfo.summary"
                        :disabled="classeUser.state !== 3"
                        class="mt-2"
                        pill
                        size="sm"
                        variant="outline-primary"
                        @click.prevent="downloadSummary(classeUser.classeInfo.summary)"
                    >
                        <span class="svg-icon svg-icon-md">
                            <inline-svg src="/media/svg/pdf.svg" width="20px" />
                        </span>
                        Voir la fiche synthèse
                    </b-button>
                </div>
            </section>
            <section v-else-if="classeUser.state === 1 || (classeUser.state === 2 && !classeUser.score)">
                <p class="mb-0">
                    <span class="font-weight-bold">Score minimum à atteindre pour valider la classe</span>
                </p>
                <h3 class="p-5 text-center my-2">
                    {{ classeUser.classeInfo.goal + '%' }}
                </h3>
            </section>
            <p v-if="classeUser.state === 2 && classeUser.score" class="mb-0">
                <span class="font-weight-bold"> Score à atteindre pour valider la classe : </span>
                {{ classeUser.classeInfo.goal + '%' }}
            </p>
            <p v-if="classeUser.state === 2 && classeUser.score">
                <span class="font-weight-bold"> Votre score: </span>
                {{ classeUser.score + '%' }}
            </p>
            <p v-if="classeUser.state === 3 && !classeUser.classeInfo.classroom" class="mb-0 font-weight-bold">Félicitations,</p>
            <span v-if="classeUser.state === 3 && !classeUser.classeInfo.classroom" class="text-center">
                Vous avez terminé cette classe avec un score de {{ Number(classeUser.score).toFixed(0) + '%' }}.
            </span>
            <div
                v-if="classeUser.state === 3 && !classeUser.classeInfo.classroom"
                v-b-tooltip.hover.top="
                    `${
                        classeUser.state !== 3
                            ? 'Validez ' + classeUser.classeInfo.goal + '% des cours pour débloquer votre attestation'
                            : ''
                    }`
                "
                class="w-100 mx-auto d-flex flex-column align-items-center justify-content-center"
            >
                <b-button
                    v-if="user.customer.id !== 2723 && user.customer.holding !== 2723 && user.customer.subholding !== 2723"
                    :class="classeUser.state !== 3 ? 'disabled' : ''"
                    :disabled="classeUser.state !== 3"
                    class="mt-2"
                    pill
                    size="sm"
                    variant="outline-primary"
                    @click="getDocument()"
                >
                    <span class="svg-icon svg-icon-md">
                        <inline-svg src="/media/svg/eye.svg" width="20px" />
                    </span>
                    Voir mon attestation
                </b-button>
                <b-button
                    :class="classeUser.state !== 3 ? 'disabled' : ''"
                    :disabled="classeUser.state !== 3"
                    class="mt-2"
                    pill
                    size="sm"
                    variant="outline-primary"
                    @click="downloadDocument()"
                >
                    <span class="svg-icon svg-icon-md">
                        <inline-svg src="/media/svg/diplome.svg" width="20px" />
                    </span>
                    Télécharger mon attestation
                </b-button>
                <b-button
                    v-if="classeUser.state === 3 && classeUser.classeInfo.summary"
                    :disabled="classeUser.state !== 3"
                    class="mt-2"
                    pill
                    size="sm"
                    variant="outline-primary"
                    @click.prevent="downloadSummary(classeUser.classeInfo.summary)"
                >
                    <span class="svg-icon svg-icon-md">
                        <inline-svg src="/media/svg/pdf.svg" width="20px" />
                    </span>
                    Voir la fiche synthèse
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import getDocument from '@/core/services/certificate.service.js';
import Nl2br from 'vue-nl2br';

export default {
    name: 'InfoClasse',
    data() {
        return {
            user: this.$store.getters.currentUser
        };
    },
    components: {
        Nl2br
    },
    props: {
        classeUser: {
            type: Object,
            default: null
        }
    },
    methods: {
        downloadDocument() {
            if (!this.classeUser.classeInfo.classroom) {
                getDocument([this.classeUser]).then((data) => {
                    data.download(data.docDefinition.info.title);
                });
            } else {
                this.loadingReleve = true;

                const handleError = () => {
                    this.$bvToast.toast('Une erreur est survenue lors du téléchargement du relevé de parcours individuel.', {
                        title: 'Erreur',
                        variant: 'danger',
                        solid: true
                    });
                };

                axios({
                    url: process.env.VUE_APP_API_URL + '/academy/classeUsers/releve/classeUser/' + this.classeUser.id,
                    method: 'GET',
                    responseType: 'blob'
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Bilan.pdf');
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(handleError)
                    .finally(() => {
                        this.loadingReleve = false;
                    });
            }
        },
        downloadSummary(url) {
            window.open(url, 'blank');
        },
        mobile() {
            return window.screen.availWidth <= 992;
        }
    }
};
</script>
