<template>
    <div v-if="classeUser" class="d-flex mt-5">
        <Chat v-if="!superviseur && !loading && classeUser.classeInfo?.classroom === 1" :sessionId="classeUser.sessionInfo?.id" />
        <!--begin::Dashboard-->
        <div class="w-100">
            <ClassroomApprenant v-if="!loading && classeUser.user_id == $store.getters.currentUser.id" :classeUser="classeUser" />
            <ClassroomTuteur
                v-else-if="!loading && (classeUser.sessionInfo.tuteur_id == $store.getters.currentUser.id || superviseur)"
                :classeUser="classeUser"
            />
            <div
                v-else-if="
                    !loading &&
                    $store.getters.currentUser.id &&
                    classeUser.user_id == $store.getters.currentUser.id &&
                    classeUser.sessionInfo.tuteur_id == $store.getters.currentUser.id
                "
            >
                <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Accès refusé</h4>
                    <p>Vous n'avez pas accès à cette classe.</p>
                    <hr />
                    <p class="mb-0">Vous allez être redirigé vers votre tableau de bord.</p>
                </div>
            </div>
        </div>
        <!--end::Dashboard-->
    </div>
</template>

<script>
import { GET_CLASSEUSER } from '@/core/services/store/api/classe_users.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ClassroomTuteur from '../content/components/Classroom/ClassroomTuteur.vue';
import ClassroomApprenant from '../content/components/Classroom/ClassroomApprenant.vue';
import Chat from '../content/components/Chat.vue';

export default {
    name: 'lesson',
    data() {
        return {
            classeUser: {},
            superviseur: false,
            loading: true
        };
    },
    components: {
        ClassroomTuteur,
        ClassroomApprenant,
        Chat
    },
    computed: {},
    async mounted() {
        //Right 2 = Superviseur
        this.checkRights([2]);
        await this.$store
            .dispatch(GET_CLASSEUSER, { classeUser_id: this.$route.params.classeUserId })
            .then((result) => {
                if (result.data.classeInfo.classroom === 1) {
                    if (
                        result.data.user_id !== this.$store.getters.currentUser.id &&
                        result.data.sessionInfo.tuteur_id !== this.$store.getters.currentUser.id &&
                        !this.superviseur
                    ) {
                        this.$router.push({ name: 'dashboard' });
                    } else {
                        this.classeUser = result.data;
                    }
                } else {
                    if (result.data.user_id !== this.$store.getters.currentUser.id) {
                        this.$router.push({ name: 'dashboard' });
                    } else {
                        this.classeUser = result.data;
                    }
                }
                this.loading = false;
            })
            .catch(() => {
                this.$router.push({ name: 'dashboard' });
            });
        await this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Lesson' }]);
    },
    methods: {
        checkRights(rightIds) {
            if (
                this.$store.getters.currentUser &&
                this.$store.getters.currentUser.rights &&
                this.$store.getters.currentUser.rights.academy
            ) {
                this.$store.getters.currentUser.rights.academy.forEach((right) => {
                    if (rightIds.includes(right.right_id)) {
                        if (right.right_id === 2) {
                            this.superviseur = true;
                        }
                    }
                });
            }
        }
    }
};
</script>
